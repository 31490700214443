import http from '@/utils/http.js'

// 登录
// export function login(data) {
//     return http.post(`/user/signin`, data)
// }

// 通过UserId查找店员信息
// export function storeInfoByUserId(data) {
//     return http.get(`/user/store/clerk/fetch/${data.userId}`, data)
// }

// 产品分类列表(全部,不分页)
export function productCategoryAll(data) {
    return http.get(`/incarey-service/product/category/all`, data)
}

// 产品列表(分页)
export function productList(data) {
    return http.get(`/incarey-service/product/list`, data)
}
// 产品详情
export function productDetail(data) {
    return http.get(`/incarey-service/product/` + data.id)
}
// 文章分类列表(全部,不分页)
export function careCategoryAll(data) {
    return http.get(`/incarey-service/article/category/all`, data)
}

// 文章列表(分页)
export function careList(data) {
    return http.get(`/incarey-service/article/list`, data)
}
// 文章详情
export function careDetail(data) {
    return http.get(`/incarey-service/article/` + data.id)
}
// 问卷详情
export function questionnaireDetail(data) {
    return http.get(`/marketing/surveyCollection/getOne/` + data.id)
}
// 问卷详情
export function questionnaireProblem(data) {
    return http.get(`/marketing/surveyCollection/getQuestions/` + data.id)
}
// 提交问卷
export function submitQuestionnaire(data) {
    return http.post(`/marketing/surveyCollection/submit`, data)
}
// 获取合作伙伴
export function partnerList(data) {
    return http.get(`/incarey/partner/list`, data)
}
