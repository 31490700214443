import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters.js";

Vue.use(Vuex);
const getDefaultState = () => {
    return {
        topShow: localStorage.getItem('topShow') || false,
    }
  }
export default new Vuex.Store({
    state: getDefaultState(),
    getters,
    mutations: {
        SET_TOPSHOW: (state, topShow) => {
            console.log('[ topShow ] >', topShow)
            localStorage.setItem('topShow', topShow)
            state.topShow = topShow
        }
    },
    actions: {
        setHeight({ commit }, height) {
            commit('SET_HEIGHT', height)
        },
        
    },
    modules: {},
});