import axios from 'axios'
import { Message, Loading } from 'element-ui'
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    timeout: 5000 // request timeout
})

// 拦截器
service.interceptors.request.use(
    config => {
        if (config.method === 'get') {
            config.data = {unused: 0} // 这个是关键点，加入这行就可以了  解决get  请求添加不上content_type 
        }
        config.headers['X-Channel'] = 83
        config.headers['X-Channel-Type'] = 1
        
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应器
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code === 0) {
            return res
        } else {
            
            return Promise.reject(new Error(res.message || 'Error'))
        }
    },
    error => {
        Loading.service().close()
        Message({
            message: error.response.data.message || '服务器数据异常！',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(new Error(error || 'Error'))
    }
)

export default service
