//en.js
export default {
    lang: "English",
    empty: 'No Data',
    suggest: {
        value1: 'Improvement suggestions for Seymoon products',
        value2: 'Thank you for providing your valuable feedback toSeymoon.Once your feedback is adopted.We willprovide you with improved product trials for free.',
        value3: '1、Name',
        value4: '2、Mobile',
        value5: '3、Age',
        value6: '4、The time you underwent the ostomysurgery',
    },
    trial: {
        value1: 'Recruitment of SeymoonProduct Experience Offcer',
        value2: "Thank you for participating in the recruitment ofSaymoon Product Upgrade Experience OffcerTenyears of grinding a 'bag', every bag is for you;",
        value3: '1、Name of Mouth Maker',
        value4: '2、The phone number of the stoma maker',
        value5: '3、Age of the stoma maker',
        value6: '4、The time when the stoma underwentthe stoma surgery',
    },
    nav: {
        value1: 'Home',
        value2: 'Product',
        value3: 'Ostomy Care',
        value4: 'About',
        value5: 'Partners',
        value6: 'FREE TRIAL',
    },
    footer: {
        value1: 'Business cooperation in China',
        value2: 'Cooperation hotline: 021-66155558',
        value3: 'E-mail:service@concarey.com',
        value4: 'Global business cooperation',
        value5: 'Cooperation hotline: 0086-21-66155558',
        value6: 'E-mail: service@concarey.com',
        value7: 'Stoma care specialist',
        value8: 'Ostomy rehabilitation center',
        value9: '沪ICP备2023023842号',
        value10: '沪公网安备31011302007447号',
    },
    service: {
        value1: 'Customer service',
        value2: 'Long press to save and open WeChat, open the recognition QR code, and add Simeon Nurse',
        value3: 'CLOSE',
    },
    index: {
        value1: 'Seymoon',
        value2: 'an economical, reliable, safe and durable solution for stoma products',
        value3: "The existing expensive ostomy products have brought a burden to ostomy patients’ lives, and as a result, many skin health issues related to product use have arisen. Seymoon provides high-quality and innovative products with performance that better match the expected 3-5 day wearing needs of current ostomy patients. The products are durable, safe, and reliable, thereby reducing the burden on patients' lives and making ostomy patients’ lives more beautiful.",
        value4: 'Spiral composite stoma chassis adhesive technology',
        value5: 'Spiral composite stoma chassis adhesive has always been mastered by a few manufacturers, and this technology can provide wider chassis performance than a single adhesive chassis, providing patients with a better product experience. Seymoon is the second manufacturer in the world to master this technology, with fully independent intellectual property rights and production technology.',
        value6: 'Full automatic velcro sealing production technology for ostomy bags making',
        value7: 'The Velcro self sealing bag has received good clinical evaluation after clinical use by a large number of ostomy patients. But currently, only a few factories have fully automatic production technology for Velcro self sealing furniture, while most manufacturers still use semi-automatic production methods, resulting in low work efficiency. Concarey comes from the main design and development of fully automatic self sealing velcro ostomy bags making, which can efficiently and reliably produce this advanced ostomy bags making technology.',
        value8: 'Parallel buckle system for two-piece ostomy bags making',
        value9: 'The traditional two-piece ostomy bags closure method usually requires compression perpendicular to the abdomen, which causes great pain to patients and has low reliability of closure. The parallel buckle system of Seymoon is convenient to wear, and patients do not need to press on the abdomen, reducing their pain.',
        value10: 'Slogan',
        value11: 'Innovation for Better Life',
        value12: '',
        value13: 'This is an era of rapid development in science and technology, which has constantly improved our lives. Seymoon is an innovative medical care brand that focuses on product innovation in the field of ostomy care. Through in-depth clinical research, we aim to explore customer needs and provide more convenient, economical, and safe ostomy care solutions for ostomy patients, making their lives better and enhancing the value of their lives.',
        value14: 'Core Technology',
        value15: 'Brand Idea',
    },
    product: {
        value1: 'Improve three major stoma issues',
        value2: 'The first packaged solution product in China to enter the national medical insurance catalog',
        value3: 'Product',
        value4: 'One-Piece Ostomy Bag',
        value5: 'Two-Piece Ostomy Bag',
        value6: 'Accessories',
        value7: 'Seymoon Two-piece Ostomy Plate',
        value8: 'The spiral structure enhances adhesion and corrosion ',
        value9: 'Detail',
        value10: '',
        value11: 'View',
    },
    productDetail: {
        value1: 'Product details',
        value2: 'Seymoon ® One piece bag',
        value3: 'Product  Features',
        value4: 'Spiral Plate,Durable Adhesion',
        value5: 'Smooth&Doclie',
        value6: 'Safe&Reliable',
        value7: 'Model',
        value8: 'Model',
        value9: 'Product Name',
        value10: 'Feature',
        value11: 'Packing list',
        value12: '1759-N10',
        value13: 'One-Piece Ostomy Bag',
        value14: 'Transparent Bag',
        value15: 'Box: Two piece',
        value16: 'FREE TRIAL',
        value17: 'BUSINESS COOPERATION',
        value18: 'product',
        value19: 'product details',
        value20: 'Business cooperation in China',
        value21: 'Cooperation hotline',
        value22: 'E-mail',
        value23: 'Global business cooperation',
        value24: 'Close',
    },
    care: {
        value1: 'Nursing Advice Guide',
        value2: 'Obtaining useful information for ostomy life, improving and improving your life',
        value3: 'Article',
        value4: 'Instructions for use',
        value5: 'Nursing Guide',
        value6: 'Life Guide',
        value7: 'Postoperative issues related to',
        value8: 'There are many allergens in daily life, and a common allergy for ostomy friends may be allergies to ostomy products. ',
        value9: 'More',
    },
    careDetail: {
        value1: 'Ostomy Care',
        value2: 'Article Details',
        value3: 'Is erythema itching unbearable? ',
    },
    partners: {
        value1: 'Powerful system',
        value2: 'The distribution network involves multiple countries and high-quality partners',
        value3: 'Distribution network',
        value4: 'Cooperation Partners',
        value5: 'Mexico',
        value6: 'Brazil',
        value7: 'Saudi Arabia',
        value8: 'Malaysia',
        value9: 'China',
    },
    about: {
        value1: 'Factory Introduction',
        value2: 'Development History of Seymoon',
        value3: 'Product improvement suggestions',
        value4: 'Focusing on the field of ostomy',
        value5: 'Continuously innovating and providing high-quality products to provide patients with high-quality services',
        value6: 'Factory Introduction',
        value7: 'Concarey Medical Technology Co., Ltd.',
        value8: 'is a medical device research and development, production, and sales enterprise centered on innovation. Concarey focuses on the field of ostomy and has launched the Seymoon series of ostomy products. Concarey has an experienced R&D team that focuses on clinical needs and constantly innovates products, earning unanimous praise from professional medical staff and patients. All core processes and formulas of Concarey are owned by itself and have complete independent intellectual property rights. So far, Concarey has established a full range of production products in the field of ostomy products. The enterprise strictly follows the ISO13485 system for management and has obtained multiple CE certificates for stoma products. Concarey has its own self-developed automated bag making production line, with high production efficiency and reliable product quality. n the future, Concarey will continue to focus on product innovation, continuously providing high-quality products and services for more stoma patients, and bringing them a better life.',
        value9: 'Development History of Seymoon',
        value10: 'the project of Seymoon ostomy Products was approved',
        value11: 'Seymoon completes the prototype validation of the Spiral composite stoma  chassis adhesive technology',
        value12: 'Seymoon completes the validation of the core production section of the new adhesive production prototype machine',
        value13: 'Seymoon has passed the biosafety testing conducted by a third-party independent  experimental institution of CMA',
        value14: 'Concarey Medical Technology Co.,Ltd was officially registered and established',
        value15: 'he first batch of stoma products  from Seymoon have been launched and have obtained a medical device production record certificate;',
        value16: 'the full automatic colloid production and manufacturing equipment of Seymoon has passed the  acceptance inspection',
        value17: 'Seymoon Innovative Mouth Set Approved for Launch',
        value18: 'stoma products from Seymoon obtained CE Certificate',
        value19: 'ostomy products from Seymoon have obtained national medical insurance consumables codes',
        value20: 'he fully automated ostomy bags making production line independently developed by Seymoon has passed the acceptance inspection',
        value21: 'Seymoon stoma accessories approved for launch',
        value22: 'Product improvement suggestions',
        value23: 'Thank you for providing your valuable feedback to Simeon. Once your feedback is accepted, we will provide you with a free trial of the improved product.',
        value24: 'FREE TRIAL',
    }
}