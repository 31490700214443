//zh.js
export default {
    lang: "中文",
    empty: '暂无数据',
    suggest: {
        value1: '司美恩产品改进建议',
        value2: '感谢您能对司美恩提出您的宝贵意见，一旦您的意见被采纳，我们将免费为您提供改进后的产品试用。',
        value3: '1、姓名',
        value4: '2、手机号',
        value5: '3、年龄',
        value6: '4、您接受造口手术的时间',
    },
    trial: {
        value1: '司美恩产品体验官招募',
        value2: '感谢您参与司美恩产品升级体验官招募十年磨一“袋”，袋袋皆为你；',
        value3: '1、造口人姓名',
        value4: '2、造口人手机号',
        value5: '3、造口人年龄',
        value6: '4、造口人接受造口手术的时间',
    },
    nav: {
        value1: '首页',
        value2: '产品介绍',
        value3: '造口护理',
        value4: '关于我们',
        value5: '合作伙伴',
        value6: '申请免费试用',
    },
    footer: {
        value1: '中国区商务合作',
        value2: '合作热线: 021-66155558',
        value3: '电子邮件:service@concarey.com',
        value4: '全球商务合作 (除中国以外)',
        value5: '合作热线: 0086-21-66155558',
        value6: '电子邮件: service@concarey.com',
        value7: '造口关爱师',
        value8: '玫瑰康复中心',
        value9: '沪ICP备2023023842号',
        value10: '沪公网安备31011302007447号',
    },
    service: {
        value1: '客服二维码',
        value2: '长按保存至微信打开识别二维码添加司美恩护理师',
        value3: '确定',
    },
    index: {
        value1: '司美恩',
        value2: '经济、可靠、安全、耐久的造口产品解决方案',
        value3: '现有昂贵的造口产品给造口人带来了生活负担，并由此产生了许多产品使用相关的皮肤健康问题。司美恩提供优质创新的产品，性能更匹配当前造口人期望的3-5天的佩戴需求，产品可耐久安全可靠，从而降低患者的生活负担，让造口人的生活更加美好。',
        value4: '螺旋复合造口底盘粘胶技术',
        value5: '螺旋造口底盘粘胶一直被少数厂家掌握，该技术能提供比单一粘胶底盘更宽广的底盘性能，给患者带来更好的产品使用体验。司美恩是全球第二家掌握该技术的厂家，拥有完全自主知识产权和生产技术。',
        value6: '造口袋全自动魔术贴封口生产技术',
        value7: '造口袋的魔术贴自封口，经过广大造口人的临床使用已经得到很好的临床评价。但目前仅有少数厂家具有魔术贴自封口的全自动生产技术，大部分厂家仍然采用半自动方式生产，生产效率低。康凯来自主设计研发的全自动自封底魔术贴造口袋，能够高效、可靠的生产这一先进的造口袋。',
        value8: '二件式造口袋的平行扣合系统',
        value9: '传统的二件式造口袋扣合方式，通常需要垂直于腹部进行按压，给患者带来巨大的痛苦，并且扣合的可靠性较低。司美恩的平行扣合系统，方便佩戴，患者不需要向腹部按压，减少了患者的痛苦。',
        value10: 'Slogan',
        value11: '创新 带来美好生活',
        value12: 'Innovation for Better Life',
        value13: '这是一个科学技术快速发展的时代，它让我们的生活日新月异不断变好。司美恩是一个创新的医疗护理品牌，专注于造口护理领域的产品创新。通过深入的临床研究，发掘客户需求，为造口人提供更便利、更经济、更安全的造口护理解决方案，让造口人的生活更美好，提升生命的价值。',
        value14: '核心技术',
        value15: '品牌理念',
    },
    product: {
        value1: '改善3大造口问题',
        value2: '国内首个进入国家医保目录的套装解决方案产品',
        value3: '产品展示',
        value4: '二件式产品',
        value5: '一件式产品',
        value6: '附件产品',
        value7: '二件式闭口袋',
        value8: '螺旋形的结构增强黏性及抗腐蚀性，更安全，黏贴时间更长；并提供更好的吸收，维持皮肤正常环境；',
        value9: '查看详情',
        value10: '',
        value11: '查看',
    },
    productDetail: {
        value1: '产品信息',
        value2: '司美恩®一件式造口袋',
        value3: '产品优势',
        value4: '螺旋底盘 黏贴持久',
        value5: '平稳服帖',
        value6: '安心可靠',
        value7: '产品型号',
        value8: '标签型号',
        value9: '型号特征',
        value10: '规格',
        value11: '包装清单',
        value12: '1759-N10',
        value13: '尿袋、引流接口',
        value14: '10片/盒',
        value15: '盒装：二件式',
        value16: '申请试用',
        value17: '商务合作',
        value18: '产品主页',
        value19: '产品详情',
        value20: '中国区商务合作',
        value21: '合作热线',
        value22: '电子邮件',
        value23: '全球商务合作 (除中国以外)',
        value24: '关闭',
    },
    care: {
        value1: '护理建议指南',
        value2: '获得对造口生活有益的信息提高改善您的生活',
        value3: '文章列表',
        value4: '司美恩使用说明',
        value5: '术后造口护理指南',
        value6: '术后回归生活指南',
        value7: '红斑瘙痒难耐？迅速缓解造口周围皮肤过敏方法！',
        value8: '生活中存在有很多过敏源，对于造口朋友来说比较常见的过敏可能是对造口用品过敏。简单的说就是：特异体质的造口者对的…',
        value9: '阅读更多',
    },
    careDetail: {
        value1: '造口护理',
        value2: '文章详情',
        value3: '红斑瘙痒难耐？迅速缓解造口周围的皮肤过敏的方法！',
    },
    partners: {
        value1: '强大的体系',
        value2: '经销网络涉及多个国家高质量合作伙伴',
        value3: '经销网络',
        value4: '合作伙伴',
        value5: '墨西哥',
        value6: '巴西',
        value7: '沙特阿拉伯',
        value8: '马来西亚',
        value9: '中国',
    },
    about: {
        value1: '工厂介绍',
        value2: '发展历程',
        value3: '改进建议',
        value4: '专注于造口领域',
        value5: '不断创新和优质的产品为患者提供优质服务',
        value6: '工厂介绍',
        value7: '上海康凯来医药科技有限公司',
        value8: '以创新为核心的医疗器械研发、生产、销售企业。康凯来专注于造口领域，推出了司美恩系列造口产品。康凯来拥有一支经验丰富的研发团队，关注临床需求，不断创新产品，获得专业医护人员和患者的一致称赞。康凯来所有核心工艺和配方，全部由自身掌握，具有完全的自主知识产权。目前为止，康凯来已经在造口产品领域，建立全系列的生产产品。企业严格按照ISO13485体系进行管理，并取得了多张造口产品CE证书。康凯来拥有自己研发自动化造口袋生产线，生产效率高，产品质量可靠。未来，康凯来将持续专注于产品创新，不断为更多造口患者提供优质产品及服务，给患者带来更好的生活。',
        value9: '发展历程',
        value10: '司美恩造口产品立项',
        value11: '司美恩完成螺旋复合粘胶工艺原型验证',
        value12: '司美恩完成新型粘胶的量产原型机核心工段验证',
        value13: '司美恩通过CMA三方独立实验机构的生物安全性检测',
        value14: '上海康凯来医药科技有限公司正式登记注册成立',
        value15: '司美恩首批造口产品上市，获得医疗器械生产备案证',
        value16: '司美恩全自动胶体生产制造设备验收通过',
        value17: '司美恩创新造口套装获批上市',
        value18: '司美恩造口产品取得CE证',
        value19: '司美恩造口产品取得国家医保耗材编码',
        value20: '司美恩自研全自动造口袋生产线验收通过',
        value21: '司美恩造口附件产品获批上市',
        value22: '司美恩产品改进建议',
        value23: '感谢您能对司美恩提出您的宝贵意见，一旦您的意见被采纳，我们将免费为您提供改进后的产品试用。',
        value24: '申请免费试用',
    }
}