<template>
    <div id="app">
        <Header></Header>
        <div class="main">
            <router-view />
        </div>
        <Footer></Footer>
        <Pop ref="pop">
            <div class="service flex_btn flex_column">
                <div class="title txt_center">{{ $t('service.value1') }}</div>
                <div class="desc txt_center">{{ $t('service.value2') }}</div>
                <img src="@/assets/images/service1.png" alt="">
                <div class="flex_btn" @click="closeService">{{ $t('service.value3') }}</div>
            </div>
        </Pop>
        <img class="float-btn" :src="$i18n.locale == 'zh' ? require('@/assets/images/service.png') : require('@/assets/images/service-en.png')" alt="" @click="openService">
    </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
import Pop from "@/components/pop.vue"
export default {
    components: {Header, Footer, Pop},
    data(){
        return {

        }
    },
    created(){
        if(localStorage.getItem('lang') == 'en') this.$i18n.locale = 'en'
        else this.$i18n.locale = 'zh',localStorage.setItem('lang', 'zh')
    },
    methods: {
        closeService(){
            this.$refs.pop.dialogVisible = false
        },
        openService(){
            this.$refs.pop.dialogVisible = true
        }
    }
}
</script>
<style lang="scss">
    .main{
        min-height: calc(100vh - 280px);
    }
    .float-btn{
        width: 158px;
        height: 158px;
        position: fixed;
        right: 26px;
        bottom: 24px;
        cursor: pointer;
        z-index: 9;
    }
    .service{
        padding: 0 0 27px;
        .title{
            font-size: 26px;
            margin-top: 10px;
        }
        .desc{
            font-size: 14px;
            color: #5D5D5D;
            margin: 6px 0 2px;
            padding: 0 20px;
        }
        img{
            width: 250px;
            height: 250px;
            margin-bottom: 28px;
        }
        .flex_btn{
            width: 198px;
            height: 54px;
            background: #00239C;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
        }
    }
</style>