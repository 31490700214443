import router from '@/router'
/*
获取图片完整路径
*/
export function getImgUrl(coverImg) {
    let imgUrl = ''
    if (coverImg) {
        let path =`${process.env.VUE_APP_STORAGE}`;
        let reg = /(http|https):\/\/([\w.]+\/?)\S*/
        if (!reg.test(coverImg)) {
            imgUrl = path + coverImg
        } else {
            imgUrl = coverImg
        }
    }
    return imgUrl
}
// 是否为空
export function isBlank(str) {
    if (str === undefined || str === null || str === '' || str === 'null' || str.length == 0) {
        return true
    } else {
        return false
    }
}
// 跳转页面
export function goPage(url){
    router.push(url)
}

// 返回页面
export function backPage(){
    router.go(-1)
}