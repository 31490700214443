<template>
    <div class='header' :style="{ 'zIndex': topShow ? 9 : 1 }">
        <div class="fixed-header dis_flex items_center" v-if="topShow">
            <img src="@/assets/images/logo-w.png" alt="" @click="$goPage('/')">
            <div class="navs dis_flex items_center">
                <div class="nav" :class="{ 'active': $route.path == item.path }" v-for="(item, index) in navs" :key="index"
                    @click="goPage(item.path)">{{ item.name }}</div>
            </div>
            <div class="fixed-header-right dis_flex items_center">
                <div class="apply flex_btn" @click="openTry" v-if="$i18n.locale == 'zh'">{{ $t('nav.value6') }}</div>
                <div class="lang flex_btn" @click="changeLang">{{ $i18n.locale == 'en' ? '中' : 'EN' }}</div>
            </div>
        </div>
        <div class="rel-header" :class="{ 'changeF': changeF }" v-else>
            <div class="header-top dis_flex items_center jus_between">
                <img :src="changeF ? require('@/assets/images/logo-w.png') : require('@/assets/images/logo.png')" alt=""
                    @click="$goPage('/')">
                <div class="header-top-right dis_flex items_center">
                    <div class="apply flex_btn" @click="openTry" v-if="$i18n.locale == 'zh'">{{ $t('nav.value6') }}</div>
                    <div class="lang flex_btn" @click="changeLang">{{ $i18n.locale == 'en' ? '中' : 'EN' }}</div>
                </div>
            </div>
            <div class="header-bottom dis_flex items_center">
                <div class="nav" :class="{ 'active': $route.path == item.path }" v-for="(item, index) in navs" :key="index"
                    @click="goPage(item.path)">{{ item.name }}</div>
            </div>
        </div>
        <Pop ref="pop">
            <div class="try">
                <div class="title txt_center">{{ questionnaireInfo.surveyName }}</div>
                <div class="desc txt_center" v-html="questionnaireInfo.surveyDescription"></div>
                <div class="form">
                    <div class="form-item" v-for="(item, index) in questionnaireProblem" :key="item.id">
                        <div class="label">{{ index + 1 + '、' + item.questionName }}</div>
                        <div class="value input" v-if="item.choices.type == 'text' && index != 3">
                            <input type="text" v-model="item.value">
                        </div>
                        <div class="value" v-if="item.choices.type == 'single'">
                            <el-radio-group v-model="item.value">
                                <el-radio v-for="(v, i) in item.choices.options" :key="i" :label="v.optionValue">{{ v.optionValue
                                }}</el-radio>
                            </el-radio-group>
                        </div>
                        <div class="value input" v-if="index == 3">
                            <el-date-picker v-model="item.value" value-format="yyyy-MM-dd" type="date"
                                :clearable="false" placeholder="">
                            </el-date-picker>
                            <img src="@/assets/images/date.png" alt="">
                        </div>
                    </div>

                </div>
                <div @click="submit" class="flex_btn">立即申请</div>
            </div>
        </Pop>
    </div>
</template>

<script>
import { questionnaireDetail, questionnaireProblem, submitQuestionnaire } from '@/api/index'
import Pop from "@/components/pop.vue"
export default {
    components: { Pop },
    data() {
        return {
            current: 0,
            questionnaireInfo: {},
            questionnaireProblem: [],
            topShow: false,
        }
    },
    computed: {
        changeF() {
            if (this.$route.path == '/' || this.$route.path == '/productDetail') return false
            else return true
        },
        navs() {
            return [{
                name: this.$t('nav.value1'),
                path: '/',
            }, {
                name: this.$t('nav.value2'),
                path: '/product',
            }, {
                name: this.$t('nav.value3'),
                path: '/care',
            }, {
                name: this.$t('nav.value4'),
                path: '/about',
            }, {
                name: this.$t('nav.value5'),
                path: '/partners',
            }]
        }
    },
    created() {
        this.$store.commit('SET_TOPSHOW', false)
        window.addEventListener("scroll", this.changeBg);
        this.getQuestionnaireDetail()
        this.getQuestionnaireProblem()
    },
    methods: {
        async submit() {
            try {
                const requestData = {
                    surveyId: this.questionnaireInfo.id,
                    answers: [],
                    tel: this.questionnaireProblem[1].value,
                }
                this.questionnaireProblem.forEach((item, index) => {
                    requestData.answers.push({
                        questionName: item.questionName,
                        questionId: item.id,
                        answerType: item.choices.type,
                        value: item.value,
                        answerType: 1,
                    })
                })
                let res = await submitQuestionnaire(requestData)
                if(res.code == 0){
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.$refs.pop.dialogVisible = false
                    this.questionnaireProblem.forEach(item => {
                        item.value = null
                    })
                }else{
                    this.$message.warning(res.message)
                }
                
            } catch (err) {
                console.log(err);

            }
        },
        async getQuestionnaireDetail() {
            try {
                const res = await questionnaireDetail({ id: process.env.NODE_ENV == 'development' ? 147 : 151 })//TODO 暂时写死,没有后台配置
                this.questionnaireInfo = res.data
            } catch (err) {
                console.log(err);
            }
        },
        async getQuestionnaireProblem() {
            const res = await questionnaireProblem({ id: process.env.NODE_ENV == 'development' ? 147 : 151 })//TODO 暂时写死,没有后台配置
            this.questionnaireProblem = res.data
            this.questionnaireProblem.forEach((item, index) => {
                item.choices = JSON.parse(item.choices)
                this.$set(item, 'value', null)
            })
        },
        openTry() {
            this.$refs.pop.dialogVisible = true
        },
        goPage(path) {
            if (this.$route.path == path) return
            this.$goPage(path)
        },
        changeBg(e) {
            let top = e.srcElement.scrollingElement.scrollTop;    // 获取页面滚动高度
            if (top > 0) {
                this.topShow = true
            } else {
                this.topShow = false
            }
            this.$store.commit('SET_TOPSHOW', this.topShow)
        },
        // 切换语言
        changeLang() {
            if (this.$i18n.locale == 'zh') {
                this.$i18n.locale = 'en'
                localStorage.setItem('lang', 'en')
            } else {
                this.$i18n.locale = 'zh'
                localStorage.setItem('lang', 'zh')
            }
            this.$forceUpdate()
        }
    },
}
</script>
<style scoped lang='scss'>
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: 224px;
}

.fixed-header {
    width: 100%;
    height: 130px;
    background: #071F71;
    box-shadow: 0px 2px 16px 0px rgba(19, 41, 120, 0.5);
    padding: 0 50px;
    box-sizing: border-box;
    z-index: 9;
    position: relative;

    img {
        width: 180px;
        height: 70px;
        cursor: pointer;
    }

    .navs {
        margin-left: 30px;

        .nav {
            color: #fff;
            font-size: 22px;
            margin-right: 60px;
            cursor: pointer;

            &.active {
                font-weight: bold;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .fixed-header-right {
        margin-left: auto;

        .apply {
            width: 194px;
            height: 65px;
            border-radius: 33px;
            background: #fff;
            font-size: 20px;
            font-weight: bold;
            color: #00239C;
            margin-right: 20px;
            cursor: pointer;
        }

        .lang {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background: #fff;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;
        }
    }
}

.rel-header {
    position: relative;
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;
    height: 224px;

    &.changeF {
        .header-top {
            border-color: #fff;
        }

        .header-bottom {
            border-color: #fff;
            color: #fff;
        }
    }

    .header-top {
        padding: 30px 0;
        border-bottom: 2px solid #CDCDCD;

        img {
            width: 180px;
            height: 70px;
            cursor: pointer;
        }

        .header-top-right {
            z-index: 1;

            .apply {
                width: 194px;
                height: 65px;
                border-radius: 33px;
                background: #00239C;
                font-size: 20px;
                font-weight: bold;
                color: #fff;
                margin-right: 20px;
                cursor: pointer;
            }

            .lang {
                width: 65px;
                height: 65px;
                border-radius: 50%;
                background: #fff;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .header-bottom {
        padding: 32px 0;
        border-bottom: 2px solid #CDCDCD;

        .nav {
            font-size: 22px;
            margin-right: 60px;
            line-height: 1.2;
            cursor: pointer;

            &.active {
                font-weight: bold;
            }
        }
    }
}

.try {
    .title {
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 1.2;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .desc {
        width: 80%;
        color: #5D5D5D;
        font-size: 12px;
        margin: 0 auto;
    }

    .form {
        height: 350px;
        overflow: auto;
        padding: 0 26px;
        box-sizing: border-box;

        .form-item {
            padding-top: 40px;

            &:last-child {
                padding-bottom: 40px;
            }

            .label {
                font-size: 18px;
                font-weight: bold;
                line-height: 1.2;
            }

            .value {
                margin-top: 6px;
                position: relative;

                &.input {
                    height: 50px;
                    border-bottom: 1px solid #000000;
                }

                input {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .el-radio-group {
                    display: flex;
                    flex-direction: column;

                    .el-radio {
                        margin-top: 5px;

                        ::v-deep .el-radio__inner {
                            width: 18px;
                            height: 18px;
                        }

                        ::v-deep .el-radio__label {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .flex_btn {
        width: 198px;
        height: 54px;
        background: #00239C;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin: 20px auto 0;
        cursor: pointer;
    }
}

::v-deep .el-date-editor.el-input {
    width: 100%;
}

::v-deep .el-input__inner {
    border: none;
    padding-left: 0 !important;
}

::v-deep .el-input__prefix {
    display: none;
}

input {
    border: 0;
    /*清除自带的2px的边框*/
    padding: 0;
    /*清除自带的padding间距*/
    outline: none;
    /*清除input点击之后的黑色边框*/
}

::-webkit-scrollbar {
    background-color: #ECECEC;
    border-radius: 4px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #00239C;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #00239C;
}
</style>
