import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'; 
import i18n from './lang'
import ElementUI from 'element-ui';
import {WOW} from 'wowjs'
import 'element-ui/lib/theme-chalk/index.css';
import * as utils from '@/utils/index.js'
import { goPage } from '@/utils/index.js'
// 引入全局css reset重置,
import '@/assets/styles/reset.css'
import '@/assets/styles/global.css'
import '@/assets/styles/animate.min.css'


Vue.prototype.$utils = utils
Vue.prototype.$goPage = goPage
Vue.prototype.$moment = moment
Vue.prototype.$wow = WOW
Vue.use(ElementUI);
Vue.config.productionTip = false


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')