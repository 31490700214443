<template>
    <div class='pop'>
        <el-dialog
            :visible.sync="dialogVisible"
            :show-close="false"
            append-to-body
            width="40%">
            <div class="dialog">
                <div class="dialog-top">
                    <img class="pop-top" src="@/assets/images/pop-top.png" alt="">
                    <img class="pop-close" src="@/assets/images/pop-close.png" alt="" v-if="showClose" @click="close">
                </div>
                <slot></slot>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        // 是否显示右上角关闭按钮
        showClose: {
            type: Boolean,
            default () {
                return false
            }
        },
    },
    data() {
        return {
            dialogVisible: false, // 是否显示弹出框
        }
    },
    created() {
        
    },
    methods: {
        close(){
            this.dialogVisible = false
        }
    },
}
</script>
<style scoped lang='scss'>
    .dialog{
        background: #fff;
        border-radius: 18px;
        overflow: hidden;
        padding-bottom: 27px;
        .dialog-top{
            width: 100%;
            position: relative;
            .pop-top{
                width: 100%;
                height: 150px;
                object-fit: cover;
            }
            .pop-close{
                width: 25px;
                height: 25px;
                position: absolute;
                top: 22px;
                right: 17px;
            }
        }
    }
    ::v-deep .el-dialog{
        border-radius: 18px;
        .el-dialog__header{
            padding: 0;
        }
        .el-dialog__body{
            padding: 0;
        }
    }
</style>