<template>
    <div class='footer dis_flex'>
        <img class="logo" src="@/assets/images/logo-w.png" alt="">
        <div class="info">
            <div class="info-top dis_flex jus_between">
                <div class="info-top-i">
                    <div class="title">{{ $t('footer.value1') }}</div>
                    <div class="desc">{{ $t('footer.value2') }}</div>
                    <div class="desc">{{ $t('footer.value3') }}</div>
                </div>
                <div class="info-top-i">
                    <div class="title">{{ $t('footer.value4') }}</div>
                    <div class="desc">{{ $t('footer.value5') }}</div>
                    <div class="desc">{{ $t('footer.value6') }}</div>
                </div>
            </div>
            <div class="info-bottom dis_flex items_center">
                <div>{{ $t('footer.value9') }}</div>
                <div>{{ $t('footer.value10') }}</div>
            </div>
        </div>
        <div class="imgs dis_flex items_center txt_center">
            <div class="imgBox">
                <img src="@/assets/images/footer1.png" alt="">
                <div class="desc"  :style="{'width': $i18n.locale == 'zh' ? '110px' : '180px'}" :class="{'en': $i18n.locale == 'en'}">{{ $t('footer.value8') }}</div>
            </div>
            <div class="imgBox">
                <img src="@/assets/images/footer1.png" alt="">
                <div class="desc" :class="{'en': $i18n.locale == 'en'}">{{ $t('footer.value7') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
           
        }
    },
    created() {
        
    },
    methods: {
        
    },
}
</script>
<style scoped lang='scss'>
    .footer{
        width: 100%;
        height: 280px;
        padding:  0 160px 50px;
        background: #191E3D;
        box-sizing: border-box;
        color: #fff;
        position: relative;
    }
    .logo{
        width: 180px;
        height: 70px;
        margin-top: 44px;
    }
    .info{
        padding: 50px 0 30px;
        .info-top{
            border-bottom: 1px solid rgba($color: #FFFFFF, $alpha: .3);
            padding-bottom: 30px;
            .info-top-i{
                &:first-child{
                    margin-right: 80px;
                }
            }
        }
        .title{
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 14px;
            white-space: nowrap;
        }
        .desc{
            font-size: 18px;
            white-space: nowrap;
        }
        .info-bottom{
            padding-top: 22px;
            div{
                margin-right: 28px;
            }
        }
    }
    .imgs{
        position: absolute;
        right: 163px;
        top: 37px;
        .imgBox{
            position: relative;
            &:first-child{
                margin-right: 46px;
            }
            img{
                width: 100px;
                height: 100px;
            }
            .desc{
                color: #FFFFFF;
                font-size: 18px;
                margin-top: 12px;
                width: 110px;
                position: absolute;
                bottom: -37px;
                left: 50%;
                transform: translateX(-50%);
                &.en{
                    bottom: -68px;
                }
            }
        }
    }


    @media only screen and (max-width: 1600px) {
        .footer{
            padding: 0 100px 50px;
        }
        .info{
            .info-top{
                .info-top-i{
                    &:first-child{
                        margin-right: 40px;
                    }
                }
            }
        }
        .imgs{
            right: 100px;
        }
    }
    @media only screen and (max-width: 1400px) {
        .footer{
            padding: 0 60px 50px;
        }
        .info{
            .info-top{
                .info-top-i{
                    &:first-child{
                        margin-right: 20px;
                    }
                }
            }
        }
        .imgs{
            right: 20px;
        }
    }
</style>