import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import("@/views/index.vue")
},{
    path: '/product',
    name: 'product',
    component: () => import("@/views/product.vue")
},{
    path: '/productDetail',
    name: 'productDetail',
    component: () => import("@/views/productDetail.vue")
},{
    path: '/care',
    name: 'care',
    component: () => import("@/views/care.vue")
},{
    path: '/careDetail',
    name: 'careDetail',
    component: () => import("@/views/careDetail.vue")
},{
    path: '/about',
    name: 'about',
    component: () => import("@/views/about.vue")
},{
    path: '/partners',
    name: 'partners',
    component: () => import("@/views/partners.vue")
},]

const router = new VueRouter({
    routes
})

export default router